import React, {useState} from 'react';
import {
    DialogContent,
    DialogActions,
    Button,
    FormControlLabel,
    Switch,
    Tooltip,
} from '@material-ui/core';
import {Alert, AlertTitle} from '@material-ui/lab';

import SignatureForm, {
    useFormController,
} from 'ccm/components/SignatureForm';
import useStyles from './styles';

function Toggle({label, value, onChange}) {
    return (
        <FormControlLabel
            control={<Switch checked={value} onChange={onChange} />}
            label={label}
        />
    );
}

export default function SignatureStep({
    ticket,
    actions,
    onNext,
    onCancel,
}) {
    const classes = useStyles();
    const [progressState, setProgressState] = useState(null);
    const [numIncomplete, setNumIncomplete] = useState(0);
    const formController = useFormController({
        ticket,
        ticketActions: actions,
        setProgressState,
        setNumIncomplete,
    });
    const [busy, setBusy] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const handleSave = async () => {
        setBusy(true);
        try {
            await formController.save();
        } finally {
            setBusy(false);
        }
    };

    const handleNext = async () => {
        await handleSave();
        onNext();
    };

    const handleChange = async () => {
        /*
        if (isEditing) {
            await handleSave();
        }
        */
        setIsEditing(!isEditing);
    };

    return (
        <>
            {busy && (
                <div className={classes.overlay}>
                    <Alert severity='success'>
                        <AlertTitle>Saving...</AlertTitle>
                    </Alert>
                    <div className={classes.disabled}></div>
                </div>
            )}
            <DialogContent dividers>
                <Toggle
                    label='Edit Signature Request'
                    value={isEditing}
                    onChange={handleChange}
                />
                {isEditing && (
                    <Alert severity='info'>
                        <AlertTitle>Edit mode active</AlertTitle>
                        Make your changes to the signature request here.
                        When finished, toggle the 'Edit Signature
                        Request' button off and continue the signing
                        process.
                    </Alert>
                )}
                <SignatureForm
                    formController={formController}
                    progressState={progressState}
                    isEditing={isEditing}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Close</Button>
                <Button
                    onClick={handleSave}
                    variant='contained'
                    color='primary'
                >
                    Save Progress
                </Button>
                <Tooltip
                    title={
                        numIncomplete > 0
                            ? `Complete ${numIncomplete} remaining field(s) to continue`
                            : ''
                    }
                    arrow
                >
                    <span>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={handleNext}
                            disabled={numIncomplete > 0}
                        >
                            Next
                        </Button>
                    </span>
                </Tooltip>
            </DialogActions>
        </>
    );
}
