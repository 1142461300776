import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import fp from 'lodash/fp';

import {Button, makeStyles} from '@material-ui/core';
import {useSnackbar} from 'notistack';

import {components, ducks, useApi} from '@arborian/narrf';

import {url_for} from 'ccm/routes';
import {useCarePlans} from 'ccm/dataSource';
import {Date} from 'ccm/components/DateTime';

const {dt2} = components;

const TABLE_OPTIONS = {
    sorting: true,
    filtering: true,
    search: true,
};

const useStyles = makeStyles(theme => ({
    details: {
        padding: theme.spacing(1),
    },
}));

const statusLookup = {
    Active: 'Active',
    Closed: 'Closed',
    Draft: 'Draft',
};

function LabeledDate({value, label}) {
    if (!value) return null;
    return (
        <>
            {label}: <Date value={value} />
            <br />
        </>
    );
}

function CarePlanItemList({focus, property, title, emptyMessage}) {
    const value = fp.get(property, focus);
    if (fp.isEmpty(value)) {
        return (
            <div>
                <strong>{title}</strong>
                <p>
                    <small>{emptyMessage}</small>
                </p>
            </div>
        );
    }
    return (
        <div>
            <strong>{title}</strong>
            <ul>
                {fp.map(
                    it => (
                        <li key={it.id}>
                            <strong>{it.status}</strong>: {it.description}
                            <br />
                            <small>
                                {/* #182439461
                                <LabeledDate
                                    value={it.createdDate}
                                    label='Created date'
                                />
                                */}
                                <LabeledDate
                                    value={it.revisedDate}
                                    label='Revised date'
                                />
                                <LabeledDate
                                    value={it.initiatedDate}
                                    label='Initiated date'
                                />
                                <LabeledDate
                                    value={it.targetDate}
                                    label='Target date'
                                />
                                <LabeledDate
                                    value={it.resolvedDate}
                                    label='Resolved date'
                                />
                            </small>
                        </li>
                    ),
                    value,
                )}
            </ul>
        </div>
    );
}

function CarePlanDetails({canWritePhi, artifact, patient, handleCreateTicket}) {
    const classes = useStyles();
    const focuses = fp.get('attributes.focuses', artifact);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const handleClick = () => {
        setBtnDisabled(true);
        handleCreateTicket(artifact, patient);
    };
    console.log(canWritePhi, !fp.get('attributes.has_ehr', artifact));
    return (
        <div className={classes.details}>
            {fp.map(
                f => (
                    <section key={f.focusId}>
                        <h6>Focus: {f.description}</h6>
                        <small>
                            Status: {f.status}
                            <br />
                            {/* #182439461
                            <LabeledDate
                                value={f.createdDate}
                                label='Created date'
                            />
                            */}
                            <LabeledDate
                                value={f.revisionDate}
                                label='Revised date'
                            />
                        </small>
                        <CarePlanItemList
                            focus={f}
                            property='goals'
                            title='Goals'
                            emptyMessage='No goals listed'
                        />
                        <CarePlanItemList
                            focus={f}
                            property='interventions'
                            title='Interventions'
                            emptyMessage='No interventions listed'
                        />
                        <br />
                    </section>
                ),
                focuses,
            )}
            {canWritePhi && !fp.get('attributes.has_ehr', artifact) && (
                <Button
                    disabled={btnDisabled}
                    variant='contained'
                    color='primary'
                    onClick={handleClick}
                >
                    Send to ticket
                </Button>
            )}
        </div>
    );
}

export default function CarePlansTable({patient, title, ...props}) {
    const api = useApi();
    const history = useHistory();
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const carePlans = useCarePlans(patient);
    const canWritePhi = fp.includes(
        'Patient.WRITE_PHI',
        fp.get('meta.allowed_actions', patient),
    );
    const newCarePlan = async () => {
        const resp = await api.fetchJson(
            patient.relationships.care_plans.links.related,
            {
                method: 'POST',
                json: {data: {type: 'CarePlan', attributes: {status: 'Draft'}}},
            },
        );
        history.push(
            url_for('carePlan', {
                patientId: patient.id,
                carePlanId: resp.data.id,
            }),
        );
    };

    const reviseCarePlan = async (ev, row) => {
        const resp = await api.fetchJson(
            patient.relationships.care_plans.links.related,
            {
                method: 'POST',
                json: {data: row.data},
            },
        );
        const href = url_for('carePlan', {
            patientId: patient.id,
            carePlanId: resp.data.id,
        });
        history.push(href);
    };

    const editCarePlan = async (ev, row) => {
        const href = url_for('carePlan', {
            patientId: patient.id,
            carePlanId: row.data.id,
        });
        history.push(href);
    };
    const deleteCarePlan = async (ev, row) => {
        await api.fetch(row.data.links.self, {method: 'DELETE'});
        dispatch(ducks.jsonapi.deleteData(row.data));
        carePlans.fetch();
    };

    const newTicket = async (rowData, patient) => {
        await api.fetchJson(
            api.url_for('practice.CarePlanTicket', {
                practice_id: patient.relationships.practice.data.id,
            }),
            {
                method: 'POST',
                json: {
                    patient_id: patient.id,
                    careplan_id: rowData.id,
                },
            },
        );
        enqueueSnackbar('Care plan ticket created.', {
            persist: false,
            variant: 'success',
        });
    };

    const handleRefresh = async () => {
        await api.fetchJson(fp.get('links.care_plan_refresh', patient), {
            method: 'POST',
        });
        await carePlans.fetch();
    };

    return (
        <dt2.DataTable
            id='careplans-table'
            title={title}
            dataSource={carePlans}
            options={TABLE_OPTIONS}
        >
            <dt2.Column
                id='careplans-col-status'
                title='Status'
                field='attributes.status'
                lookup={statusLookup}
            />
            {/* #182439461
            <dt2.Column
                title='Created date'
                field='attributes.createdDate'
                type='date'
                component={Date}
                filtering={false}
            />
            */}
            <dt2.Column
                id='careplans-col-revision-date'
                title='Revision date'
                field='attributes.revisionDate'
                type='date'
                component={Date}
                filtering={false}
            />
            <dt2.Column
                id='careplans-col-review-date'
                title='Next review date'
                field='attributes.nextReviewDate'
                type='date'
                component={Date}
                filtering={false}
            />
            <dt2.DetailPanel
                key='cp-detail'
                render={row => {
                    return (
                        <CarePlanDetails
                            canWritePhi={canWritePhi}
                            artifact={row.data}
                            patient={patient}
                            handleCreateTicket={newTicket}
                        />
                    );
                }}
            />
            <dt2.Action
                id='cp-refresh'
                key='cp-refresh'
                name='refresh'
                free
                onClick={handleRefresh}
                tooltip='Refresh'
                icon='refresh'
            />
            {canWritePhi && (
                <>
                    <dt2.Action
                        id='cp-add'
                        key='cp-add'
                        icon='add'
                        free
                        tooltip='Create new care plan'
                        onClick={newCarePlan}
                    />
                    <dt2.Action
                        id='cp-dupe'
                        key='cp-dupe'
                        icon='content_copy'
                        tooltip='Duplicate & revise care plan'
                        onClick={reviseCarePlan}
                    >
                        {row => ({
                            hidden:
                                fp.get('data.attributes.status', row) ===
                                'Draft',
                        })}
                    </dt2.Action>
                    <dt2.Action
                        id='cp-edit'
                        key='cp-edit'
                        icon='edit'
                        tooltip='Edit care plan'
                        onClick={editCarePlan}
                    >
                        {row => ({
                            hidden: fp.get('data.attributes.has_ehr', row),
                        })}
                    </dt2.Action>
                    <dt2.Action
                        id='cp-delete'
                        key='cp-delete'
                        icon='delete'
                        tooltip='Delete care plan'
                        onClick={deleteCarePlan}
                    >
                        {row => ({
                            hidden: fp.get('data.attributes.has_ehr', row),
                        })}
                    </dt2.Action>
                </>
            )}
        </dt2.DataTable>
    );
}
