import React from 'react';
import fp from 'lodash/fp';
import * as R from 'react-router-dom';
import {useSelector} from 'react-redux';

import {
    makeStyles,
    Badge,
    Button,
    IconButton,
    Link,
    Tooltip,
} from '@material-ui/core';
import {
    AccountCircle,
    Mail,
    NewReleases,
    OpenInNew,
} from '@material-ui/icons';

import {useApi, ducks} from '@arborian/narrf';
import {url_for} from 'ccm/routes';
import {useCurrentUser} from 'ccm/dataSource/misc';

const useStyles = makeStyles(theme => ({
    authLabel: {
        display: 'flex',
        alignItems: 'center',
    },
    userLabel: {
        padding: 6,
        marginTop: 4,
    },
}));

const inboxIcon = unreadMessages => {
    if (unreadMessages > 0) {
        return (
            <Badge badgeContent={unreadMessages} color='secondary'>
                <Mail />
            </Badge>
        );
    } else {
        return <Mail />;
    }
};

function MessageIndicator() {
    const curUser = useCurrentUser();
    const unreadMessages = fp.get(
        'attributes.unread_message_count',
        curUser,
    );
    return (
        <Button
            size='large'
            component={R.Link}
            to={url_for('inbox')}
            startIcon={inboxIcon(unreadMessages)}
        >
            Inbox
        </Button>
    );
}

export default function AuthInfo() {
    const classes = useStyles();

    const api = useApi();
    const userinfo = useSelector(ducks.auth.selectUserinfo);
    const curUser = useCurrentUser();
    let userLabel = 'Anonymous';
    if (userinfo) {
        userLabel =
            userinfo.name || userinfo.username || userinfo.email;
    }
    if (curUser && !userLabel) {
        userLabel =
            curUser.attributes.primary_email || curUser.attributes.sub;
    }

    const newTab = () => {
        window.open(window.location);
    };

    const tryNext = async () => {
        const data = await api.fetchJson(api.url_for('auth.own_jwt'), {
            method: 'POST',
        });
        const url = new URL(window.location);
        if (url.hostname === 'localhost') {
            url.port = 3000;
        } else {
            url.hostname = 'next.' + url.hostname;
        }
        url.pathname = '/';
        url.search = 'jwt=' + data.jwt;
        window.open(url, '_blank', 'noopener=true');
    };

    if (!api.isAuthorized()) {
        return (
            <>
                <Button
                    id='login-link'
                    color='inherit'
                    component={R.Link}
                    to={url_for('login')}
                >
                    Sign in
                </Button>
                <Link
                    id='register-link'
                    component={R.Link}
                    to={url_for('register')}
                >
                    Register
                </Link>
            </>
        );
    }
    return (
        <div className={classes.authLabel}>
            <MessageIndicator />
            <AccountCircle />
            <Link component={R.Link} to={url_for('profile')}>
                {userLabel}
            </Link>
            <Tooltip title='Try the next version of SamePage! (beta)'>
                <IconButton onClick={tryNext}>
                    <NewReleases />
                </IconButton>
            </Tooltip>
            <Tooltip title='New Tab'>
                <IconButton onClick={newTab}>
                    <OpenInNew />
                </IconButton>
            </Tooltip>
            <Button
                color='inherit'
                component={R.Link}
                to={url_for('logout')}
            >
                Sign out
            </Button>
        </div>
    );
}
